import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  // Container,
  // Label,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Doughnut } from "react-chartjs-2"
import { Bar } from "react-chartjs-2"
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MiniWidget from "./mini-widget"
import Spinearea from "./splineArea"
function Dashbaord1Chart() {
  const [master, setmaster] = useState({ date: "2023-10-13" })

  // staff attendance data

  const series = [30, 55, 41]
  const options = {
    labels: ["IMPACT Kerala LTD", "DSM Thrissur", "DSM Pathanamthitta"],
    colors: ["#ef0c2c", "#3251e7", "#787b7e"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  //donut chart 1
  const data = {
    labels: ["Active ", "Inactive"],
    datasets: [
      {
        data: [300, 210],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }
  //daunout2
  const data2 = {
    labels: ["Visit", "No Vist"],
    datasets: [
      {
        data: [250, 50],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const data3 = {
    labels: ["Paid", "Unpaid"],
    datasets: [
      {
        data: [225, 25],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const data4 = {
    labels: ["Unpaid", "Paid"],
    datasets: [
      {
        data: [25, 225],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  // bar chart data

  const data5 = {
    labels: ["Thrissur", "Ernakulam", "Kollam", "Wayanad"],
    datasets: [
      {
        label: "Project Count ",
        backgroundColor: "rgba(52, 195, 143, 0.8)",
        borderColor: "rgba(52, 195, 143, 0.8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
        hoverBorderColor: "rgba(52, 195, 143, 0.9)",
        data: [15, 20, 25, 40],
      },
    ],
  }

  const option5 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.3,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at 0
            stepSize: 10, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }

  // up paid splitup chart

  const seriesunpaid = [30, 55, 41]
  const optionsunpaid = {
    labels: ["IMPACT Kerala LTD", "DSM Thrissur", "DSM Pathanamthitta"],
    colors: ["#EE4B2B", "rgba(52, 195, 143, 0.8)", "#00FFFF"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  // line target charts

  const seriesTarget = [
    { name: "target", data: [26, 26, 26, 26, 26, 26, 26] },
    { name: "Achieved", data: [14, 11, 16, 12, 17, 13, 12] },
  ]
  const optionsTarget = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#5b73e8", "#f1b44c"],
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      width: [2, 2],
      curve: "straight",
    },
    title: {
      text: "Target and Achieved",
      align: "left",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.1,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 5,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Target",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }
  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const reportsTwo = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total Projects",
      value: 240,
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 40,
      chartwidth: 70,
      // badgeValue: "49",
      color: "success",
      // desc: "Active Customers",
      series: series3,
      options: options3,
      width: 3,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      title: "Completed/Dropped Projects",
      value: "2,866",
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      // badgeValue: "19",
      // desc: "UnPaid customers",
      series: series3,
      options: options3,
      width: 3,
    },

    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Ongoing Projects",
      value: "3,172",
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      // chartwidth: 45,
      // badgeValue: "9",
      color: "danger",
      desc: " Pending Visit",
      series: series3,
      options: options3,
      width: 3,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-down-bold",
      title: "No of Package",
      value: 71,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "19",
      color: "danger",
      desc: "Total staff",
      series: series3,
      options: options3,
      width: 3,
    },
  ]
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={`PMS - Dashboard`} />
          <Row id="dashboard-head ">
            <AvForm>
              <Col>
                <Row className="dashboard-filter">
                  <Col md="2 mb-2">
                    <Select
                      isClearable
                      name="batch"
                      width={50}
                      placeholder="District"
                      options={[
                        {
                          label: "Thiruvananthapuram",
                          value: "Thiruvananthapuram",
                        },
                        { label: "Kollam", value: "Kollam" },
                        { label: "Pathanamthitta", value: "Pathanamthitta" },
                        { label: "Alappuzha", value: "Alappuzha" },
                        { label: "Kottayam", value: "Kottayam" },
                        { label: "Idukki", value: "Idukki" },
                        { label: "Ernakulam", value: "Ernakulam" },
                        { label: "Thrissur", value: "Thrissur" },
                        { label: "Palakkad", value: "Palakkad" },
                        { label: "Malappuram", value: "Malappuram" },
                        { label: "Wayanad", value: "Wayanad" },
                        { label: "Kozhikode", value: "Kozhikode" },
                        { label: "Kannur", value: "Kannur" },
                        { label: "Kasargod", value: "Kasargod" },
                      ]}
                    />
                  </Col>

                  <Col md="2 mb-2">
                    <Select
                      isClearable
                      name="student"
                      placeholder="Local Body"
                      options={[
                        { label: "Municipality", value: "municipality" },
                        { label: "Cooperation", value: "Cooperation" },
                        { label: "Block Panchayat", value: "Blockpanchayat" },
                        { label: "Gram Panchayat", value: " Grampanchayat" },
                      ]}
                    />
                  </Col>
                  <Col md="2 mb-2">
                    <Select placeholder="Constituency" />
                  </Col>
                  <Col md="2 mb-2">
                    <Select placeholder="Executing Office" />
                  </Col>
                  <Col md="2 mb-2">
                    <Select placeholder="District Mission" />
                  </Col>
                  <Col md="2">
                    <div>
                      <button
                        title="reset"
                        style={{
                          border: "none",
                          color: "green",
                          fontSize: "20px",
                          background: "unset",
                        }}
                        className="fas fa-undo mt-1"
                      ></button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </AvForm>
          </Row>
          <Row>
            <Col md={12}>
              <MiniWidget reports={reportsTwo} />
            </Col>
          </Row>
          <Row style={{ rowGap: "20px" }}>
            <Col md="12">
              <Row>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        Funding Agency Wise split up
                      </h4>
                      <Spinearea />
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <h5>DSM Office Wise Project Count(Ongoing)</h5>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          md="6"
                        >
                          <AvForm>
                            <AvField
                              name="date"
                              type="date"
                              value={master?.date}
                            />
                          </AvForm>
                        </Col>
                      </Row>
                      <ReactApexChart
                        options={optionsunpaid}
                        series={seriesunpaid}
                        type="pie"
                        height="200"
                        className="apex-charts"
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <h6>District Wise Project Count(Completed & Ongoing)</h6>
                      <Bar
                        width={474}
                        height={200}
                        data={data5}
                        options={option5}
                      />
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">
                  <Card style={{ height: "100%", marginBottom: "0px" }}>
                    <CardBody>
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="pie"
                        height="200px"
                        className="apex-charts"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Dashbaord1Chart
