import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { setTitle } from "../../../helpers/functions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { get } from "../../../helpers/api_helper"
import { isArray } from "lodash"

const languages = [
  {
    _id: "67809d7948d98bccfc1825ba",
    label: "English",
    key: "English",
    value: null,
  },

  {
    _id: "67809d7948d98bccfc1825c4",
    label: "മലയാളം (Malayalam)",
    key: "Malayalam",
    value: "malayalam",
  },
]

const initialMaster = {
  name: {},
  required: { name: false, mobile: false, email: false },
  languages: [
    {
      _id: "67809d7948d98bccfc1825ba",
      label: "English",
      key: "English",
      value: null,
    },
  ],

  questions: [
    {
      category: "FSW",
      subCategory: "Infrastructure",
      aspects: [
        {
          title: "Toilet Facility",
          parameter: [
            {
              title: "Improved sanitation",
              indicators: [
                { title: "Functional flush /pour flush type" },
                { title: "Functional flush /pour flush type" },
              ],
            },
            {
              title: "Functionality of toilets",
              indicators: [{ title: "Functional toilets facility is " }],
            },
          ],
        },
      ],
    },
  ],
}

const AssessmentManage = () => {
  const [master, setMaster] = useState(initialMaster)
  const [selected, setSelected] = useState({})

  //OPTIONS
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])

  useEffect(() => {
    fetchCategory()
  }, [])

  useEffect(() => {
    fetchSubCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [master.category])

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  // const handleChange = e => {
  //   let { name, value } = e.target
  //   setMaster(pre => ({ ...pre, [name]: value }))
  // }

  const fetchCategory = async () => {
    try {
      const res = await get("options/assessment-category")
      setCategoryOptions(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSubCategory = async () => {
    try {
      if (!master.category) {
        return
      }

      const res = await get(
        `options/assessment-sub-category?category=${master.category}`,
      )
      setSubCategoryOptions(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  console.log("master :>> ", master)
  console.log("selected :>> ", selected)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Assessment")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Language</Label>
                          <Select
                            options={languages}
                            classNamePrefix="select2-selection"
                            value={master.languages}
                            isClearable={false}
                            isMulti
                            onChange={e => {
                              if (
                                !e.map(item => item.label).includes("English")
                              ) {
                                return
                              }
                              setMaster(pre => ({ ...pre, languages: e }))
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      {master.languages.map(item => (
                        <Col md="3">
                          <div>
                            <Label>Assessment Name {item.key}</Label>
                            <AvField
                              name={item.key.toLocaleLowerCase()}
                              className="form-control"
                              type="text"
                              value={
                                master.name[item.key.toLocaleLowerCase()] || ""
                              }
                              placeholder="Feedback Form Name"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Name is required",
                                },
                              }}
                              onChange={e => {
                                setMaster(pre => ({
                                  ...pre,
                                  name: {
                                    ...pre.name,
                                    [e.target.name]: e.target.value,
                                  },
                                }))
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>

                    {master.questions.map(question => {
                      return (
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Category</Label>
                                  <Select
                                    options={categoryOptions}
                                    classNamePrefix="select2-selection"
                                    value={selected.category || null}
                                    onChange={e => {
                                      handleSelect(e, "category")
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Sub Category</Label>
                                  <Select
                                    options={subCategoryOptions}
                                    classNamePrefix="select2-selection"
                                    value={selected.subCategory || null}
                                    onChange={e => {
                                      handleSelect(e, "subCategory")
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {question.aspects.map(aspect => {
                              return (
                                <Row>
                                  <Col md="3">
                                    <AvField
                                      name="Title"
                                      placeholder="Aspect Title"
                                    />
                                  </Col>

                                  {aspect.parameter.map(param => {
                                    return (
                                      <Row>
                                        <Card>
                                          <CardBody>
                                            <Row>
                                              <Col md="3">
                                                <AvField
                                                  name="Title"
                                                  placeholder="Parameter Title"
                                                />
                                              </Col>

                                              {param.indicators.map(el => {
                                                return (
                                                  <Row>
                                                    <Col md="3">
                                                      <AvField
                                                        name="Title"
                                                        placeholder="Indicateor Title"
                                                      />
                                                    </Col>
                                                    <Col md="3">
                                                      <AvField
                                                        name="Title"
                                                        placeholder="Mark"
                                                      />
                                                    </Col>
                                                    <Col md="3">
                                                      <AvField
                                                        name="Title"
                                                        placeholder="Type"
                                                      />
                                                    </Col>
                                                  </Row>
                                                )
                                              })}
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      </Row>
                                    )
                                  })}
                                </Row>
                              )
                            })}
                          </CardBody>
                        </Card>
                      )
                    })}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AssessmentManage
