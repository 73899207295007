import React from "react"
import { Label } from "reactstrap"
import Select from "react-select"

const ReactSelect = ({
  onChange = () => {},
  name = "",
  label = "",
  options,
  isInvalid = false,
  value = null,
  error = "This field is required",
  ...props
}) => {
  return (
    <>
      <div className="mb-3">
        {label && <Label>{label}</Label>}
        <Select
          id={name}
          style={{ appearance: "auto" }}
          menuPosition="absolute"
          options={options}
          onChange={e => onChange(e, name)}
          value={value}
          {...props}
        />
        {isInvalid && (
          <div style={{ display: "block" }} className="invalid-feedback">
            {error}
          </div>
        )}
      </div>
    </>
  )
}

export default ReactSelect
