import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { setTitle } from "../../helpers/functions"

const PagesStarter = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Starter Page")} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesStarter
