import React, { useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  dateConverter,
  setTitle,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { MDBDataTable } from "mdbreact"
import { del, get, post, put } from "../../../helpers/api_helper"
import { useEffect } from "react"
import Icon from "../../../components/Common/Icons"
import Swal from "sweetalert2"

import toastr from "toastr"

const InstituteCategory = () => {
  const [master, setMaster] = useState({})
  const [rows, setRows] = useState([])

  const formRef = useRef()

  useEffect(() => {
    fetchTableData()
    // eslint-disable-next-line
  }, [])

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleSubmit = async () => {
    try {
      if (master.id) {
        const response = await put("institute-category", master)
        fetchTableData()
        toastr.success(response.message)
        reset()
      } else {
        const response = await post("institute-category", master)
        fetchTableData()
        toastr.success(response.message)
        reset()
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toastr.error(error?.response?.data?.message)
      } else {
        console.log(error.message)
      }
    }
  }

  const handleUpdate = async ({ name, _id }) => {
    setMaster({ name, id: _id })
    toTop()
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`institute-category/${id}`)
          .then(res => {
            toastr.success(res.message)
            fetchTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const fetchTableData = async () => {
    try {
      const response = await get(`institute-category`)

      const data = response.data.map((item, index) => {
        item.id = index + 1

        item.addedBy = item?.addedBy?.fullName

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.action = (
          <>
            <Icon
              icon={"edit"}
              title={"Edit"}
              onClick={() => {
                handleUpdate(item)
              }}
            />

            <Icon
              icon={"delete"}
              title={"Delete"}
              onClick={() => {
                handleDelete(item._id)
              }}
            />
          </>
        )

        return item
      })
      setRows(data)
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message)
    }
  }

  const reset = () => {
    formRef.current.reset()
    setMaster({})
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: "Date ",
        field: "date",
      },
      {
        label: "Time ",
        field: "time",
      },
      {
        label: " Name",
        field: "name",
      },
      {
        label: "Staff",
        field: "addedBy",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem={setTitle("Institute Category")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Name</Label>
                        <AvField
                          name="name"
                          placeholder="Name"
                          type="text"
                          errorMessage="Enter institute category  name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          onChange={handleChange}
                          value={master.name || ""}
                        />
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <Button
                          color={master.id ? "warning" : "primary"}
                          type="submit"
                          className="me-2"
                        >
                          {master.id ? "Update" : "Submit"}
                        </Button>

                        <Button
                          onClick={() => reset()}
                          type="reset"
                          color="danger"
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="institute-category"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default InstituteCategory
